.btn {
  @apply border-2 inline-block font-bold transition-all ease-in-out duration-500 cursor-pointer outline-none hover:outline-none focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed disabled:pointer-events-none whitespace-nowrap;
  &:not([class*="outline"]) {
    @apply hover:bg-opacity-75 hover:border-opacity-0 focus:bg-opacity-75 focus:border-opacity-0;
  }
}
.sm {
  @apply text-sm p-[0.9rem] leading-3;
}
.md {
  @apply text-base p-3 leading-4;
}
.lg {
  @apply text-lg p-4;
}
.primary {
  @apply bg-primary border-primary text-white hover:text-white;
}
.secondary {
  @apply bg-secondary border-secondary text-white hover:text-white;
}
.tertiary {
  @apply bg-tertiary border-tertiary text-gray-900 hover:text-gray-900;
}
.white {
  @apply bg-white border-white text-gray-900 hover:text-gray-900;
}
.black {
  @apply bg-black border-black text-white hover:text-white;
}
.highlight {
  @apply gradient border-0 text-white bg-size-150 bg-left hover:text-white hover:bg-right;
  padding: calc(theme("spacing.3") + 2px);
}
.outlinePrimary {
  @apply border-primary hover:bg-primary hover:text-white;
}
.outlineSecondary {
  @apply border-secondary hover:bg-secondary hover:text-white;
}
.outlineTertiary {
  @apply border-tertiary hover:bg-tertiary hover:text-white;
}
.outlineWhite {
  @apply border-white hover:bg-white hover:text-black;
}
.outlineBlack {
  @apply border-black hover:bg-black hover:text-white;
}
.outlineHighlight {
  @apply gradient-border border-0 hover:text-white hover:border-0;
  padding: calc(theme("spacing.3") + 2px);
}
.btnLinkStyle {
}
.error {
  @apply bg-error border-error hover:bg-error-dark text-white hover:text-white;
}
.success {
  @apply bg-success border-success hover:bg-success-dark text-white hover:text-white;
}
.warning {
  @apply bg-warning border-warning hover:bg-warning-dark text-white hover:text-white;
}
.outlineError {
  @apply border-error hover:bg-error hover:text-white;
}
.outlineSuccess {
  @apply border-success hover:bg-success hover:text-white;
}
.outlineWarning {
  @apply border-warning hover:bg-warning hover:text-white;
}
